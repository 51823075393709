import { useState, useRef, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,

  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,Dialog,DialogContent,DialogActions,Button
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import User1 from 'assets/images/users/user-round.svg';
import useLogin from 'hooks/useLogin';

// assets
import { IconLogout, IconUserScan,IconInfoCircle  } from '@tabler/icons-react';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const customization = useSelector((state) => state.customization);
  const { logout } = useLogin();

  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  // 打开对话框的函数
  const handleOpenConfirmDialog = () => {
    setShowConfirmDialog(true);
  };

  // 关闭对话框的函数
  const handleCloseConfirmDialog = () => {
    setShowConfirmDialog(false);
  };
  
  // 修改后的退出函数，加入关闭对话框的操作
  const handleLogout = async () => {
    logout();
    setShowConfirmDialog(false); // 执行退出后关闭对话框
  };


  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Avatar
        src={User1}
        sx={{
          ...theme.typography.mediumAvatar,
          margin: '8px 0 8px 8px !important',
          cursor: 'pointer',
          border: '2px solid', // 添加边框
          borderColor: theme.palette.background.paper, // 边框颜色与主题背景色相同
          boxShadow: theme.shadows[3], // 添加阴影效果
          width: 40, // 调整头像宽度
          height: 40, // 调整头像高度
        }}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        onMouseEnter={() => setOpen(true)} 
        color="inherit"
      />


      <Popper
        placement="top-end" // 改为 "top-end"
        open={open}
        anchorEl={anchorRef.current}
        transition
        style={{ zIndex: 1300 }}
        
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1], // 增大偏移量的绝对值使其更远
              }
            }
          ]
        }}
        
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper
            onMouseEnter={() => setOpen(true)} // 当鼠标在弹出菜单上时保持打开状态
            onMouseLeave={() => setOpen(false)} // 鼠标离开弹出菜单时关闭
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <List
                    component="nav"
                    sx={{
                      width: '100%',
                      maxWidth: 350,
                      minWidth: 150,
                      backgroundColor: theme.palette.background.paper,
                      borderRadius: '10px',
                      [theme.breakpoints.down('md')]: {
                        minWidth: '100%'
                      },
                      '& .MuiListItemButton-root': {
                        mt: 0.5
                      }
                    }}
                  >
                    <ListItemButton sx={{ borderRadius: `${customization.borderRadius}px` }} onClick={() => navigate('/profile')}>
                      <ListItemIcon>
                        <IconUserScan stroke={1.5} size="1.3rem" />
                      </ListItemIcon>
                      <ListItemText primary={<Typography variant="body2">个人设置</Typography>} />
                    </ListItemButton>

                    <ListItemButton sx={{ borderRadius: `${customization.borderRadius}px` }} onClick={() => navigate('/about')}>
                      <ListItemIcon>
                        <IconInfoCircle stroke={1.5} size="1.3rem" />
                      </ListItemIcon>
                      <ListItemText primary={<Typography variant="body2">查看教程</Typography>} />
                    </ListItemButton>

                    <ListItemButton sx={{ borderRadius: `${customization.borderRadius}px` }} onClick={handleOpenConfirmDialog}>
                      <ListItemIcon>
                        <IconLogout stroke={1.5} size="1.3rem" />
                      </ListItemIcon>
                      <ListItemText primary={<Typography variant="body2">退出登录</Typography>} />
                    </ListItemButton>
                  </List>
                </MainCard>
                
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
       
      </Popper>
      <Dialog
        open={showConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography>确定要退出登录吗？</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog}>取消</Button>
          <Button onClick={handleLogout} autoFocus>
            确定
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfileSection;
