// 导入 React 和 Redux 钩子
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
// 导入路由导航钩子
import { useNavigate } from 'react-router-dom';
// 导入 API 和工具函数
import { API } from 'utils/api';
import { calculateQuota } from 'utils/common';
// 导入 UI 组件
import { styled } from '@mui/material/styles';
import {

  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Chip,

} from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

// 导入其他组件和动作
import ProfileSection from './ProfileSection';


// 主题样式调整
const CardStyle = styled(Card)(({ theme }) => ({
  background: theme.typography.menuChip.background,
  marginBottom: '22px',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: '157px',
    height: '157px',
    background: theme.palette.primary[200],
    borderRadius: '50%',
    top: '-105px',
    right: '-96px'
  }
}));

const MenuCard = () => {

  const account = useSelector((state) => state.account);
  const navigate = useNavigate();
  const [inputs, setInputs] = useState([]);

  // 异步加载用户信息
  const loadUser = async () => {
    // 假定 API.get 返回 Promise
    const res = await API.get(`/api/user/self`);
    const { success, message, data } = res.data;
    
    if (success) {
      setInputs(data);
    } else {
      // showError 是一个假设存在的函数，用于展示错误信息
      showError(message);
    }
  };


  useEffect(() => {
    if (account.user) {
      loadUser();
    }
  }, [account.user?.username]); 

  return (
    <CardStyle>
      <CardContent sx={{ p: 2 }}>
        <List sx={{ p: 0, m: 0 }}>
          <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
            <ProfileSection />
            <ListItemText
              primary={
                <Box>
                  <Chip 
                    label={`${account.user?.username} - ${inputs.group}`} 
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => navigate('/profile')}
                    sx={{ cursor: 'pointer', mr: 1 }} // 添加样式
                  />
                  {/* 添加登出按钮 */}
                  
                </Box>
              }
              secondary={
                <Typography
                  sx={{ mt: 1, display: 'flex' }}
                  variant="subtitle1"
                >

                  <Chip
                    icon={<MonetizationOnIcon />} // 在Chip内部使用图标
                    label={calculateQuota(inputs.quota)}
                    size="small"
                    variant="outlined" // 设置变体，使其看起来更像按钮
                    sx={{ cursor: 'pointer' }} // 进一步强调可点击的视觉效果
                  />

                </Typography>
              }
            />
            
          </ListItem>
        </List>
      </CardContent>
    </CardStyle>
  );
};

export default MenuCard;
