import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Stack, IconButton, Tooltip, useTheme } from '@mui/material';
import { API } from 'utils/api';
import { Link, useLocation } from 'react-router-dom';
import ThemeButton from 'ui-component/ThemeButton';
import { marked } from 'marked';
import { showError, showNotice } from 'utils/common';
import Campaign from '@mui/icons-material/Campaign';

const Home = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const account = useSelector((state) => state.account);

  const displayNotice = async () => {
    try {
      const res = await API.get('/api/notice');
      const { success, message, data } = res.data;
      if (success && data) { 
        const htmlNotice = marked(data);
        showNotice(htmlNotice, true);
        // 更新最后显示时间
        localStorage.setItem('lastNoticeTime', Date.now().toString());
      } else if (!success) {
        showError(message || '加载公告失败');
      }
    } catch (error) {
      showError('无法加载公告: ' + (error.message || '未知错误'));
    }
  };

  const checkAndDisplayNotice = () => {
    if (account.user) {
      const lastNoticeTime = localStorage.getItem('lastNoticeTime');
      const currentTime = Date.now();
      const hoursPassed = (currentTime - parseInt(lastNoticeTime || '0')) / (1000 * 60 * 60);
    
      if (!lastNoticeTime || hoursPassed >= 24) {
        displayNotice();
      }
    }
  };
  

  useEffect(() => {
    checkAndDisplayNotice();
  }, [account.user]); // 添加 account 作为依赖项
  const NavButton = ({ to, children }) => (
    <Button
      component={Link}
      to={to}
      sx={{
        color: pathname === to ? theme.palette.primary.main : theme.palette.text.primary,
        fontWeight: 'medium',
        position: 'relative',
        overflow: 'hidden',
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '2px',
          backgroundColor: theme.palette.primary.main,
          transform: pathname === to ? 'scaleX(1)' : 'scaleX(0)',
          transition: 'transform 0.3s ease-in-out'
        },
        '&:hover::after': {
          transform: 'scaleX(1)'
        },
        '&:hover': {
          backgroundColor: 'transparent',
          color: theme.palette.primary.main
        }
      }}
    >
      {children}
    </Button>
  );

  return (
    <>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      <Stack spacing={2} direction="row" alignItems="center">
        <Tooltip title="公告">
          <IconButton 
            onClick={displayNotice} 
            color="primary"
            sx={{
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'scale(1.1)',
                backgroundColor: theme.palette.primary.light
              }
            }}
          >
            <Campaign />
          </IconButton>
        </Tooltip>
        
        <ThemeButton />
        <NavButton to="/chatweb">对话</NavButton>
        <NavButton to="/home">返回首页</NavButton>
      </Stack>
    </>
  );
};

export default Home;